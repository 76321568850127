import { useState } from "react";
import { NavLink } from "react-router-dom";
import { formatText } from "../../../common/formatString";
import { getValue } from "../../../utils/lodash";

export default function DashboardCard(props) {
	const [dashboardCardArray] = useState([
		{
			title: "Fresh Leads",
			count: "018",
			imgIcon: "/images/dashboard/dashboard-home-page/Group 8496.svg",
			href: "/fresh-leads",
		},
		{
			title: "Followup Overdue",
			count: "011",
			imgIcon: "/images/dashboard/dashboard-home-page/Group 8564.svg",
			href: "/followup-overdue",
		},
		{
			title: "Interested",
			count: "018",
			imgIcon: "/images/dashboard/dashboard-home-page/Group 8566.svg",
			href: "/interested",
		},
		{
			title: "Not Interested",
			count: "018",
			imgIcon: "/images/dashboard/dashboard-home-page/Group 8565.svg",
			href: "/not-interested",
		},
	]);

	const url = (url) => {
		switch (url) {
			case "interested":
				return "/interested?page=1";
			case "not_interested":
				return "/not-interested?page=1";
			case "follow_up":
				return "/followup-overdue?page=1";
			case "fresh_lead":
				return "/fresh-leads?page=1";
			default:
				break;
		}
	};

  const images = (path) => {
		switch (path) {
			case "interested":
				return "/images/dashboard/dashboard-home-page/Group 8566.svg";
			case "not_interested":
				return "/images/dashboard/dashboard-home-page/Group 8565.svg";
			case "follow_up":
				return "/images/dashboard/dashboard-home-page/Group 8564.svg";
			case "fresh_lead":
				return "/images/dashboard/dashboard-home-page/Group 8496.svg";
			default:
				break;
		}
  }
	return (
		<>
			{getValue(props, "data", []).map((tempObj, index) => {
				return (
					<NavLink to={url(tempObj.status)} key={`dashboard-${index}`}>
						<div className="dashboard-card-wrapper d-flex align-items-center justify-content-between">
							<div>
								<p className="dashboard-card-wrapper__title">
									{formatText(tempObj.status)}
								</p>
								<h2 className="dashboard-card-wrapper__count">
									{tempObj.count}
								</h2>
							</div>
							<img src={images(tempObj.status)} className="img-fluid" />
						</div>
					</NavLink>
				);
			})}
		</>
	);
}
