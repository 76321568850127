import React, { useEffect, useState } from "react";
import { Input, Label } from "reactstrap";
// import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import DatePicker from "react-datepicker";
import Select from "react-select";


const InputField = (props) => {
  switch (props.inputType) {
    case "TEXT":
      return (
        <div className="form-group">
          <Input
            type="text"
            id={props.id}
            name={props.name}
            onChange={(e) => props.onChange(e, props.setPath)}
            value={props.value}
            placeholder={props.placeholder}
            autoComplete="off"
            disabled={props.disabled}
          />
          {/* <label className="on-hover" htmlFor={props.id}>
            {props.label}
          </label> */}
          {props.validator && (
            <span className="form-error">
              {props.validator.current.message(props.label, props.value, "required")}
            </span>
          )}
        </div>
      );
      case "TEXTAREA":
      return (
        <div className="form-group">
          <Input
            type="textarea"
            rows={props.rows}
            id={props.id}
            name={props.name}
            onChange={(e) => props.onChange(e, props.setPath)}
            value={props.value}
            placeholder={props.placeholder}
            autoComplete="off"
          />
          {/* <label className="on-hover" htmlFor={props.id}>
            {props.label}
          </label> */}
          {props.validator && (
            <span className="form-error">
              {props.validator.current.message(props.label, props.value, "required")}
            </span>
          )}
        </div>
      );
    case "TEXTALPHA":
      return (
        <div className="form-group">
          <Input
            type="text"
            id={props.id}
            name={props.name}
            onChange={(e) => props.onChange(e, props.setPath)}
            value={props.value}
            placeholder={props.placeholder}
            autoComplete="off"
          />
          <label className="on-hover" htmlFor={props.id}>
            {props.label}
          </label>
          {props.validator && (
            <span className="form-error">
              {props.validator.current.message(
                props.label,
                props.value,
                "required|alpha"
              )}
            </span>
          )}
        </div>
      );
    case "EMAIL":
      return (
        <div className="form-group">
          <Input
            type="email"
            autoComplete="off"
            id={props.id}
            name={props.name}
            onChange={(e) => props.onChange(e, props.setPath)}
            value={props.value}
            placeholder={props.placeholder}
          />
          <label className="on-hover" htmlFor={props.id}>
            {/* {props.label} */}
          </label>
          {props.validator && (
            <span className="form-error">
              {props.validator.current.message(props.label, props.value, "required")}
            </span>
          )}
        </div>
      );
    case "NUMBER":
      return (
        <div className="form-group">
          <Input
            autoComplete="off"
            type="number"
            id={props.id}
            name={props.name}
            onChange={(e) => props.onChange(e, props.setPath)}
            value={props.value}
            placeholder={props.placeholder}
          />
          {/* <label className="on-hover" htmlFor={props.id}>
            {props.label}
          </label> */}
          {props.validator && (
            <span className="form-error">
              {props.validator.current.message(
                props.label,
                props.value,
                "required|integer"
              )}
            </span>
          )}
        </div>
      );

    case "PASSWORD":
      return (
        <div className="form-group">
          <Input
            type="password"
            autoComplete="off"
            id={props.id}
            name={props.name}
            onChange={(e) => props.onChange(e, props.setPath)}
            placeholder={props.placeholder}
          />
          <label className="on-hover" htmlFor={props.id}>
            {/* {props.label} */}
          </label>
          {props.validator && (
            <span className="form-error">
              {props.validator.current.message(props.label, props.value, "required")}
            </span>
          )}
        </div>
      );

    case "INPUT_REACT_SELECT":
      return (
        <div className="form-group">
          <Select
            type="select"
            name={props.name}
            onChange={props.onChange}
            options={props.options}
            placeholder={props.placeholder}
            value={props.value}
            selected = {props.selected}
            isMulti={props.isMulti?props.isMulti:false}
          ></Select>
          {props.validator && (
                <span className="form-error">
                  {props.validator.current.message(props.label, props.value, "required")}
                </span>
              )}
        </div>
      );

      case "CHECKBOX":
        return (
          <label className="checkbox-container">
            {props.label}
            <input
              type="checkbox"
              onChange={() => props.onChange(props.list)}
              value={props.value}
              checked={props.checked(props.list)}
            />
            <span className="checkmark"></span>
          </label>
        );

    //   case "INPUT_SELECT":
    //     return (
    //       <div className="form-group">
    //         <Input
    //           type="select"
    //           multiple={props.isMulti}
    //           name={props.name}
    //           onChange={(e) => props.onChange(e, props.setPath)}
    //         >
    //           <option>Select {props.label}</option>
    //           {props.options.map((items) => (
    //             <option
    //               value={items[props.valueInput]}
    //               selected={
    //                 items[props.valueInput] === props.selectedItem ? true : false
    //               }
    //             >
    //               {items[props.textInput]}
    //             </option>
    //           ))}
    //         </Input>
    //         <Label className="on-hover">{props.label}</Label>
    //         {props.validator && (
    //           <span className="form-error">
    //             {props.validator.message(props.label, props.value, "required")}
    //           </span>
    //         )}
    //       </div>
    //     );

    //   // phone number with country flag
    //   case "INPUT_REACT_SELECT_COUNTYCODE":
    //     return (
    //       <div className="form-group form-react-select">
    //         <Select
    //           type="select"
    //           name={props.name}
    //           onChange={(e) => props.onChange(e, props.setPath)}
    //           options={option}
    //           placeholder="Select"
    //           value={props.value}
    //         ></Select>
    //         <Label className="on-hover on-top">Country Code</Label>
    //         {props.validator && (
    //           <span className="form-error">
    //             {props.validator.message(props.label, props.value, "required")}
    //           </span>
    //         )}
    //       </div>
    //     );





    //   case "CHECKBOX":
    //     return (
    //       <label className="checkbox-container">
    //         {props.label}
    //         <input
    //           type="checkbox"
    //           onChange={() => props.onChange(props.list)}
    //           value={props.value}
    //           checked={props.checked(props.list)}
    //         />
    //         <span className="checkmark"></span>
    //       </label>
    //     );

    //   // calendar
    //   case "DATETYPE-1":
    //     return (
    //       <ReactDatePicker
    //         selected={props.selected}
    //         onChange={(date) => props.onChange(date)}
    //         isClearable
    //         minDate={props.minDate}
    //         inline
    //         selectsStart={props.selectsStart}
    //         selectsEnd={props.selectsEnd}
    //         startDate={props.startDate}
    //         endDate={props.endDate}
    //         monthsShown={2}
    //       />
    //     );

      case "DATETYPE":
        return (
          <div className="form-group react-datepicker-single">
            <DatePicker
              className="form-control"
              selected={props.selected}
              onChange={(date) => props.onChange(date)}
              isClearable
              // peekNextMonth
              // startDate={props.startDate}
              // showMonthDropdown
              // showYearDropdown
              placeholderText={props.placeholder}
              dropdownMode="select"
              // maxDate={props.maxDate}
              // minDate={props.minDate}
              // dateFormat="DD MMM YYYY"
            />
            {props.validator && (
                <span className="form-error">
                  {props.validator.current.message(props.label, props.value, "required")}
                </span>
              )}
            {/* <Label className="on-hover on-top">{props.label}</Label> */}
          </div>
        );


    default:
      return <h1>Input not valid</h1>;
  }
};

export default InputField;

