import Layout from '@components/layout/layout.js';

export default function AboutUs() {
  return (
    <Layout>
      <div className="container">
        <h2>about us</h2>
      </div>
    </Layout>
  );
}
