export default function DashboardFooter() {
  return (
    <div className="dashboard-footer-wrapper">
      <div className="container-fluid">
        <ul>
          <li className="dashboard-footer-wrapper__list">
            <a
              href="#0"
              className="dashboard-footer-wrapper__footer-link dashboard-footer-wrapper__footer-link--color"
            >
              @ Healthtunnel
            </a>
          </li>
          <li className="dashboard-footer-wrapper__list">
            <a href="#0" className="dashboard-footer-wrapper__footer-link">
              Terms of Service
            </a>
          </li>
          <li className="dashboard-footer-wrapper__list">
            <a href="#0" className="dashboard-footer-wrapper__footer-link">
              Privacy Policy
            </a>
          </li>
          <li className="dashboard-footer-wrapper__list">
            <a href="#0" className="dashboard-footer-wrapper__footer-link">
              Send Feedback
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
